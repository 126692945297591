import * as React from 'react';
import classNames from 'classnames';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../../common/constants';
import { FreemiumBannerResponsiveProps } from '../FreemiumBannerResponsive.types';
import { EditorXLogo } from './assets/editorXLogo';
import * as Translations from './constants';
import style from './style/FreemiumBannerResponsive.scss';

const FreemiumBannerResponsive: React.FC<
  FreemiumBannerResponsiveProps & { className?: string }
> = props => {
  const {
    id = 'WIX_ADS',
    className,
    translations,
    useOverlay = false,
    direction,
    href = '',
  } = props;

  const translatedBannerText = translations.mainText!;

  const [textBeforeLogo, textAfterLogo] = translatedBannerText.split(
    Translations.logoPlaceHolder,
  );

  const translatedLogoText = translations.logoText!;

  return (
    <div
      id={id}
      className={classNames(
        className,
        style.freemiumBannerResponsive,
        style[direction],
        useOverlay ? style.overlay : '',
      )}
    >
      {useOverlay ? (
        <div data-testid={TestIds.overlay} className={style.overlay} />
      ) : (
        <Link className={style.link} href={href} target="_blank" rel="nofollow">
          <span className={style.text}>
            {textBeforeLogo}
            <EditorXLogo
              className={classNames(style.logo, style[direction])}
              logoText={translatedLogoText}
            />
            {textAfterLogo}
          </span>
        </Link>
      )}
    </div>
  );
};

export default FreemiumBannerResponsive;
